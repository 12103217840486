import React from 'react'
import { Table, Radio, IconButton } from 'evergreen-ui'

const StageRenderer = props => {
  const isSimple = typeof props.answers[0] === 'string' ? true : false
  return (
    <Table marginBottom={16}>
      <Table.Head>
        {!isSimple && (
          <Table.TextHeaderCell flexBasis={150} flexShrink={0} flexGrow={0}>
            správná možnost
          </Table.TextHeaderCell>
        )}
        <Table.TextHeaderCell>text</Table.TextHeaderCell>
        {!isSimple && (
          <Table.TextHeaderCell flexBasis={320} flexShrink={0} flexGrow={0}>
            obrázek
          </Table.TextHeaderCell>
        )}
        <Table.HeaderCell flexBasis={70} flexShrink={0} flexGrow={0} />
      </Table.Head>
      <Table.Body>
        {props.answers.map((answer, i) => (
          <Table.Row key={!!answer.id ? answer.id : i}>
            {!isSimple && (
              <Table.Cell flexBasis={150} flexShrink={0} flexGrow={0}>
                <Radio
                  name="answer"
                  size={16}
                  checked={answer.correct}
                  onClick={() => props.setCorrectAnswer(answer)}
                />
              </Table.Cell>
            )}
            <Table.TextCell>{isSimple ? answer : answer.text}</Table.TextCell>
            {!isSimple && (
              <Table.TextCell flexBasis={320} flexShrink={0} flexGrow={0}>
                {answer.image}
              </Table.TextCell>
            )}
            <Table.Cell flexBasis={70} flexShrink={0} flexGrow={0}>
              {/*<IconButton appearance="minimal" icon="edit" />*/}
              <IconButton
                appearance="minimal"
                intent="danger"
                icon="cross"
                onClick={() => props.remove(i)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default StageRenderer
