import React from 'react'
import { Button } from 'evergreen-ui'

const StageRenderer = props =>
  props.stages.map((stage, index) => {
    if (index === props.selectedStage)
      return (
        <Button
          key={index}
          appearance="primary"
          intent="success"
          marginRight={4}
          marginBottom={4}
        >
          {index + 1}
        </Button>
      )
    else
      return (
        <Button
          key={index}
          appearance="primary"
          intent="none"
          marginRight={4}
          marginBottom={4}
          onClick={() => props.selectStage(index)}
        >
          {index + 1}
        </Button>
      )
  })

export default StageRenderer
