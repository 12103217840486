export const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ align: [] }],
    ['clean'],
  ],
}

export function convertShortCodeToHTML(text) {
  const exp = /\[(\w*).(\w*)=\\"(.*?)\\"\]/gm
  return text.replace(exp, (_s, el, attr, val) => {
    return '<' + el + ' ' + attr + '=\\"' + val + '\\">'
  })
}

export function referencedAssets(quest) {
  let images = [],
    sounds = []
  for (const s of quest.stages) {
    for (const ss of s.substages) {
      if (ss.image && ss.image.length > 0) {
        images.push(ss.image)
      }
      if (ss.sound && ss.sound.length > 0) {
        sounds.push(ss.sound)
      }
      if (
        ss.little_help &&
        ss.little_help.image &&
        ss.little_help.image.length > 0
      ) {
        images.push(ss.little_help.image)
      }
      if (
        ss.little_help &&
        ss.little_help.sound &&
        ss.little_help.sound.length > 0
      ) {
        sounds.push(ss.little_help.sound)
      }
      if (ss.big_help && ss.big_help.image && ss.big_help.image.length > 0) {
        images.push(ss.big_help.image)
      }
      if (ss.big_help && ss.big_help.sound && ss.big_help.sound.length > 0) {
        sounds.push(ss.big_help.sound)
      }
      if (ss.answers) {
        for (const a of ss.answers) {
          if (a.image && a.image.length > 0) {
            images.push(a.image)
          }
          if (a.sound && a.sound.length > 0) {
            sounds.push(a.sound)
          }
        }
      }
    }
  }
  return { images, sounds }
}
