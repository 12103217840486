import React, { Component } from 'react'
import {
  Heading,
  Pane,
  Button,
  IconButton,
  Dialog,
  TextInputField,
  Text,
  toaster,
} from 'evergreen-ui'
import saveAs from 'file-saver'
import Dropzone from 'react-dropzone'

// import QuestSelector from './components/QuestSelector'
import QuestDisplayer from './components/QuestDisplayer'
import QuestEditor from './components/QuestEditor'

import * as tpls from './templates'

import questFile from './ru.json'

import { convertShortCodeToHTML, referencedAssets } from './utils'

// app
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saved: true,
      newQuestDialog: false,
      newQuestName: '',
      newQuestId: 0,
      quest: null,
      selectedQuest: 0,
      selectedStage: 0,
      selectedSubstage: 0,
      leftSidebarToggle: true,
      pathImages: '/Grafika_',
      pathSounds: '/Audio_',
      images: [],
      sounds: [],
      referencedAssets: {
        images: [],
        sounds: [],
      },
    }

    this.selectQuest = this.selectQuest.bind(this)
    this.updateQuest = this.updateQuest.bind(this)
    this.selectStage = this.selectStage.bind(this)
    this.selectSubstage = this.selectSubstage.bind(this)
    this.setStageCoordines = this.setStageCoordines.bind(this)
    this.updateStage = this.updateStage.bind(this)
    this.updateSubstage = this.updateSubstage.bind(this)
    this.updateQuestionHelp = this.updateQuestionHelp.bind(this)
    this.addStage = this.addStage.bind(this)
    this.removeStage = this.removeStage.bind(this)
    this.addSubstage = this.addSubstage.bind(this)
    this.removeSubstage = this.removeSubstage.bind(this)
    this.exportToFile = this.exportToFile.bind(this)
    this.addAnswer = this.addAnswer.bind(this)
    this.setCorrectAnswer = this.setCorrectAnswer.bind(this)
    this.changeAnswerType = this.changeAnswerType.bind(this)
    this.removeAnswer = this.removeAnswer.bind(this)
    this.questionNumber = this.questionNumber.bind(this)
    this.checkQuest = this.checkQuest.bind(this)
    this.getReferencedAssets = this.getReferencedAssets.bind(this)
    this.removeAsset = this.removeAsset.bind(this)

    window.onbeforeunload = () => {
      if (!this.state.saved) return 'Are you sure you want to leave?'
    }
  }

  // getters

  selectQuest(quest) {
    this.setState({
      quest: quest,
    })
  }

  selectStage(stage) {
    this.setState({
      selectedStage: stage,
      selectedSubstage: 0,
    })
  }

  getStage() {
    return this.state.quest !== null
      ? this.state.quest.stages[this.state.selectedStage]
      : null
  }

  selectSubstage(substage) {
    this.setState({
      selectedSubstage: substage,
    })
  }

  getSubstage() {
    if (this.getStage() === null) return
    return this.getStage().substages[this.state.selectedSubstage]
  }

  // update functions

  updateStage(update) {
    this.setState(state => {
      let q = state.quest
      let st = q.stages[state.selectedStage]
      q.stages[state.selectedStage] = Object.assign({}, st, update)
      return {
        quest: q,
        saved: false,
      }
    })
  }

  updateSubstage(update) {
    this.setState(state => {
      let q = state.quest
      let sub = q.stages[state.selectedStage].substages[state.selectedSubstage]
      let newObj = {}
      if (update.type === 'questions') {
        newObj = JSON.parse(JSON.stringify(tpls.questionTemplate))
      }
      q.stages[state.selectedStage].substages[
        state.selectedSubstage
      ] = Object.assign(newObj, sub, update)
      return {
        quest: q,
        saved: false,
      }
    })
  }

  updateQuestionHelp(help, prop, value) {
    this.setState(state => {
      let q = state.quest
      const _help =
        q.stages[state.selectedStage].substages[state.selectedSubstage][help]
      q.stages[state.selectedStage].substages[state.selectedSubstage][
        help
      ] = Object.assign({}, _help, { [prop]: value })
      return {
        quest: q,
        saved: false,
      }
    })
  }

  setStageCoordines(update) {
    this.setState(state => {
      let q = state.quest
      const _cords = q.stages[state.selectedStage].coordinates
      q.stages[state.selectedStage].coordinates = Object.assign(
        {},
        _cords,
        update,
      )
      return {
        quest: q,
      }
    })
  }

  addStage() {
    this.setState(state => {
      let q = state.quest
      let l = q.stages.length
      let newStage = JSON.parse(JSON.stringify(tpls.stageTemplate))
      newStage.id = l + 1
      q.stages.push(newStage)
      let newSubstage = JSON.parse(JSON.stringify(tpls.substageTemplate))
      newSubstage.id = parseInt(l + 1 + '01')
      q.stages[l].substages.push(newSubstage)
      return {
        quest: q,
        selectedStage: l,
        selectedSubstage: 0,
        saved: false,
      }
    })
  }

  removeStage() {
    this.setState(state => {
      let q = state.quest
      q.stages.splice(state.selectedStage, 1)
      return {
        quest: q,
        selectedStage: state.selectedStage - 1,
        saved: false,
      }
    })
  }

  addSubstage() {
    this.setState(state => {
      let q = state.quest
      let ol = this.getStage().substages.length
      let newSubstage = JSON.parse(JSON.stringify(tpls.substageTemplate))
      newSubstage.id = parseInt(
        state.selectedStage + 1 + (ol > 8 ? '' : '0') + (ol + 1),
      )
      q.stages[state.selectedStage].substages.push(newSubstage)
      return {
        quest: q,
        selectedSubstage: ol,
        saved: false,
      }
    })
  }

  removeSubstage() {
    this.setState(state => {
      let q = state.quest
      q.stages[state.selectedStage].substages.splice(state.selectedSubstage, 1)
      return {
        quest: q,
        selectedSubstage: state.selectedSubstage - 1,
        saved: false,
      }
    })
  }

  addAnswer(answer) {
    this.setState(state => {
      let q = state.quest
      let sub = q.stages[state.selectedStage].substages[state.selectedSubstage]
      let al = this.getSubstage().answers
        ? this.getSubstage().answers.length
        : 0
      if (typeof answer === 'object') {
        let newAnswer = JSON.parse(JSON.stringify(tpls.answerTemplate))
        newAnswer = Object.assign({}, newAnswer, answer, {
          id: parseInt(this.getSubstage().id + (al > 8 ? '' : '0') + (al + 1)),
        })
        sub.answers.push(newAnswer)
      }
      if (typeof answer === 'string') {
        sub.correct_answer.push(answer)
      }

      return {
        quest: q,
        saved: false,
      }
    })
  }

  updateAnswer(answer) {
    this.setState(state => {
      let q = state.quest
      let sub = q.stages[state.selectedStage].substages[state.selectedSubstage]
      if (typeof answer === 'string') {
        sub.correct_answer = answer
      }
      if (typeof answer === 'object') {
        sub.answers.map(a => {
          if (a.id === answer.id) {
            a.correct = true
          } else a.correct = false
          return a
        })
      }

      return {
        quest: q,
      }
    })
  }

  removeAnswer(id, type) {
    this.setState(state => {
      let q = state.quest
      let sub = q.stages[state.selectedStage].substages[state.selectedSubstage]
      type === 'input'
        ? sub.correct_answer.splice(id, 1)
        : sub.answers.splice(id, 1)

      return {
        quest: q,
      }
    })
  }

  changeAnswerType(type) {
    this.setState(state => {
      let q = state.quest
      let sub = q.stages[state.selectedStage].substages[state.selectedSubstage]

      if (type === 'input') {
        sub.correct_answer = sub.answers.map(a => a.text)
        sub.answers = null
      }
      if (type === 'choose') {
        sub.answers = sub.correct_answer.map((a, i) => {
          return { id: i, text: a }
        })
        sub.correct_answer = null
      }

      return {
        quest: q,
      }
    })
  }

  setCorrectAnswer(answer) {
    if (typeof answer !== 'object') return

    this.setState(state => {
      let q = state.quest
      let sub = q.stages[state.selectedStage].substages[state.selectedSubstage]

      sub.answers.map(a => {
        if (a.id === answer.id) {
          a.correct = true
        } else a.correct = false
        return a
      })

      return {
        quest: q,
      }
    })
  }

  getReferencedAssets() {
    this.setState(state => {
      return {
        referencedAssets: referencedAssets(state.quest),
      }
    })
  }

  questionNumber(stage, substage) {
    let qn = 0
    for (let si = 0; si < this.state.quest.stages.length; si++) {
      for (
        let ssi = 0;
        ssi < this.state.quest.stages[si].substages.length;
        ssi++
      ) {
        if (this.state.quest.stages[si].substages[ssi].type === 'questions')
          qn++
        if (stage === si && substage === ssi) return qn
      }
    }
    return qn
  }

  newQuest(name, id) {
    this.setState(state => {
      let q = JSON.parse(JSON.stringify(tpls.questTemplate))
      q.name = name
      q.id = id
      q.image_files = q.image_files.replace(/{questId}/, () => id)
      q.sound_files = q.sound_files.replace(/{questId}/, () => id)

      return {
        quest: q,
      }
    })
    this.addStage()
  }

  updateQuest(update) {
    this.setState(state => {
      return {
        quest: Object.assign({}, state.quest, update),
        saved: false,
      }
    })
  }

  exportToFile() {
    const stringified = JSON.stringify(this.state.quest)

    saveAs(
      new Blob([convertShortCodeToHTML(stringified)], {
        type: 'application/json',
      }),
      'quest[' + this.state.quest.name + '].json',
    )
    this.setState({ saved: true })
  }

  fileDrop(files) {
    let failed = 0,
      success = 0
    if (!this.state.quest && files[0].type === 'application/json') {
      this.importQuest(files[0])
    } else if (this.state.quest) {
      for (const file of files) {
        try {
          if (file.type === 'image/jpeg' || file.type === 'image/png') {
            this.importAsset(file, 'image')
            success++
          } else if (file.type === 'audio/mp3') {
            this.importAsset(file, 'sound')
            success++
          } else {
            failed++
          }
        } catch (err) {
          failed++
        }
      }
      if (failed === 0) {
        toaster.success('Zpracováno ' + files.length + ' souborů', {
          id: 'importing',
          // description: 'success: ' + success + ', failed: ' + failed,
        })
      } else if (failed > 0) {
        toaster.warning('Zpracováno ' + files.length + ' souborů', {
          id: 'importing',
          description: 'success: ' + success + ', failed: ' + failed,
        })
      }
    } else {
      toaster.danger('Nejprve vytvořte quest!', {
        id: 'import-failed',
      })
    }
  }

  removeAsset(type, id) {
    this.setState(state => {
      let q = state.quest
      if (type === 'image') {
        q.all_images.splice(id, 1)
      }
      if (type === 'sound') {
        q.all_sounds.splice(id, 1)
      }
      return {
        quest: q,
      }
    })
  }

  importAsset(file, type) {
    this.setState(state => {
      let q = state.quest
      if (type === 'image') {
        q.all_images.push({ img: state.pathImages + '/' + file.name })
      } else if (type === 'sound') {
        q.all_sounds.push({ sound: state.pathSounds + '/' + file.name })
      }
      return {
        quest: q,
      }
    })
  }

  importQuest(file) {
    if (this.check4Changes()) return

    const reader = new FileReader()
    reader.onload = () => {
      const quest = JSON.parse(reader.result)
      this.setState({
        quest,
      })
      this.getReferencedAssets()
      this.findImagesPath(quest.all_images)
      this.findSoundsPath(quest.all_sounds)
    }
    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.readAsText(file)
  }

  findImagesPath(images) {
    if (images && images.length >= 3) {
      const p = images[0].img.split('/')
      this.setState({
        pathImages: '/' + p[p.length - 2],
      })
    }
  }

  findSoundsPath(sounds) {
    if (sounds && sounds.length >= 3) {
      const p = sounds[0].sound.split('/')
      this.setState({
        pathSounds: '/' + p[p.length - 2],
      })
    }
  }

  check4Changes() {
    if (!this.state.saved) {
      toaster.warning('Nemáte uložené změny!', {
        id: 'not-saved',
        description: 'Uložte nejprve do souboru.',
      })
      return true
    }
    return false
  }

  isValidQuestion(question) {
    if (!!question.answers) {
      let trues = 0
      for (const answer of question.answers) {
        if (answer.correct) {
          trues++
        }
      }
      return trues > 0
    } else if (!!question.correct_answer) {
      return question.correct_answer.length > 0
    } else {
      return false
    }
  }

  checkQuest() {
    this.getReferencedAssets()
    const quest = this.state.quest
    let valid = true

    toaster.closeAll()

    for (const s in quest.stages) {
      for (const sb in quest.stages[s].substages) {
        const substage = quest.stages[s].substages[sb]
        switch (substage.type) {
          case 'questions':
            if (!this.isValidQuestion(substage)) {
              valid = false
              toaster.danger('Zkontrolujte otázku', {
                id: 'qe',
                description:
                  'stanoviště: ' +
                  (parseInt(s) + 1) +
                  ' - substanoviště: ' +
                  (parseInt(sb) + 1),
              })
            }
            break
          case 'substages':
            // this.isValidQuestion(substage)
            break

          default:
            valid = false
            toaster.danger('Neznámý typ substanoviště', {
              id: 'sbe',
              description:
                'stanoviště: ' +
                (parseInt(s) + 1) +
                ' - substanoviště: ' +
                (parseInt(sb) + 1),
            })
            break
        }
      }
    }

    if (valid) {
      toaster.success('Quest je validní', {
        id: 'qo',
      })
    }

    this.setState({ checked: true })

    return valid
  }

  render() {
    return (
      <div>
        <Pane
          background="tint2"
          position="fixed"
          width={this.state.leftSidebarToggle ? 270 : 48}
          height="100vh"
          borderRight="default"
          overflow="scroll"
        >
          <IconButton
            icon={this.state.leftSidebarToggle ? 'menu-closed' : 'menu-open'}
            margin={8}
            onClick={() => {
              this.setState(state => {
                return {
                  leftSidebarToggle: !state.leftSidebarToggle,
                }
              })
            }}
          />
          <Pane
            display={this.state.leftSidebarToggle ? 'block' : 'none'}
            padding={24}
          >
            <Heading size={700} margin={16}>
              Quest Composer
            </Heading>
            {/* <QuestSelector
              quests={this.state.quests}
              selectQuest={this.selectQuest}
              selectedQuest={this.state.quest}
            /> */}
            <Button
              iconBefore="add"
              width="100%"
              marginTop={24}
              marginBottom={24}
              onClick={() => {
                if (this.check4Changes()) return
                this.setState({
                  newQuestDialog: true,
                })
              }}
            >
              Nový quest
            </Button>
            <Pane marginBottom={8}>
              <Dropzone onDrop={file => this.fileDrop(file)}>
                <Pane padding={16}>
                  <Text>Načíst quest ze souboru</Text>
                </Pane>
              </Dropzone>
            </Pane>
            <Button
              iconBefore="export"
              width="100%"
              marginBottom={48}
              onClick={() => {
                if (this.check4Changes()) return
                this.setState({ quest: questFile })
              }}
            >
              Načíst vzorový quest
            </Button>
            <Button
              iconBefore="predictive-analysis"
              width="100%"
              marginBottom={8}
              intent="success"
              onClick={this.checkQuest}
              disabled={this.state.quest === null ? true : false}
            >
              1. Zkontrolovat soubor
            </Button>
            <Button
              iconBefore="saved"
              width="100%"
              marginBottom={8}
              onClick={this.exportToFile}
              disabled={this.state.quest === null ? true : !this.state.checked}
            >
              2. Uložit do souboru
            </Button>
          </Pane>
        </Pane>
        <Pane
          position="fixed"
          width={
            'calc(30% + ' +
            (this.state.leftSidebarToggle ? '0px' : '222px') +
            ')'
          }
          height="100vh"
          padding={24}
          marginLeft={this.state.leftSidebarToggle ? 270 : 48}
          borderRight="default"
          overflow="scroll"
        >
          <QuestDisplayer
            quest={this.state.quest}
            updateQuest={this.updateQuest}
            addStage={this.addStage}
            removeStage={this.removeStage}
            updateStage={this.updateStage}
            addSubstage={this.addSubstage}
            removeSubstage={this.removeSubstage}
            stage={this.getStage() ? this.getStage() : {}}
            selectedStage={this.state.selectedStage}
            selectStage={this.selectStage}
            stageSubstages={this.getStage() ? this.getStage().substages : []}
            setStageCoordines={this.setStageCoordines}
            selectSubstage={this.selectSubstage}
            selectedSubstage={this.state.selectedSubstage}
            // pathImages={this.state.pathImages}
            // pathSounds={this.state.pathSounds}
            referencedAssets={this.state.referencedAssets}
            removeAsset={this.removeAsset}
            updatePath={(type, path) => {
              if (type === 'image') {
                this.setState({
                  pathImages: path,
                })
              }
              if (type === 'sound') {
                this.setState({
                  pathSounds: path,
                })
              }
            }}
          />
        </Pane>
        <Pane
          position="absolute"
          width="calc(70% - 270px)"
          height="100vh"
          padding={24}
          marginLeft="calc(30% + 270px)"
        >
          <QuestEditor
            selectedStage={this.state.selectedStage}
            selectedSubstage={this.state.selectedSubstage}
            substage={this.getStage() ? this.getSubstage() : null}
            updateSubstage={this.updateSubstage}
            updateQuestionHelp={this.updateQuestionHelp}
            addAnswer={this.addAnswer}
            setCorrectAnswer={this.setCorrectAnswer}
            changeAnswerType={this.changeAnswerType}
            removeAnswer={this.removeAnswer}
            questionNumber={this.questionNumber}
          />
        </Pane>
        <Dialog
          isShown={this.state.newQuestDialog}
          title="Nový quest"
          intent="success"
          onCloseComplete={() =>
            this.setState({
              newQuestDialog: false,
              newQuestName: '',
              newQuestId: null,
            })
          }
          confirmLabel="Přidat"
          onConfirm={() => {
            this.setState({ newQuestDialog: false })
            this.newQuest(this.state.newQuestName, this.state.newQuestId)
          }}
          isConfirmDisabled={this.state.newQuestName.length > 0 ? false : true}
        >
          <TextInputField
            label="název questu"
            width="100%"
            value={this.state.newQuestName ? this.state.newQuestName : ''}
            onChange={e =>
              this.setState({
                newQuestName: e.target.value,
              })
            }
            placeholder="Вышеград"
          />
          <TextInputField
            label="id questu (pokud nevíte, nevadí)"
            width="100%"
            value={this.state.newQuestId ? this.state.newQuestId : ''}
            onChange={e =>
              this.setState({
                newQuestId: parseInt(e.target.value),
              })
            }
            placeholder="2048"
          />
          <TextInputField
            label="cesta k obrázkům"
            width="100%"
            value={this.state.pathImages ? this.state.pathImages : ''}
            onChange={e =>
              this.setState({
                pathImages: e.target.value,
              })
            }
            placeholder="/Grafika_Hradcany"
          />
          <TextInputField
            label="cesta k audiu"
            width="100%"
            value={this.state.pathSounds ? this.state.pathSounds : ''}
            onChange={e =>
              this.setState({
                pathSounds: e.target.value,
              })
            }
            placeholder="/Audio_Hradcany"
          />
        </Dialog>
      </div>
    )
  }
}

export default App
