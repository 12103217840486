export const questTemplate = {
  id: null,
  quest_id: null,
  type: 'quests',
  name: null,
  description: null,
  state: null,
  bought: null,
  activated: null,
  expired: null,
  people: null,
  estimated_duration: null,
  sound_info: null,
  image_files: 'https://dev.questintour.com/wp-json/qit-api/v1/assets/{questId}/images',
  sound_files: 'https://dev.questintour.com/wp-json/qit-api/v1/assets/{questId}/sounds',
  all_images: [],
  all_sounds: [],
  intro: null,
  stages: [],
  partners: [],
  results: []
}
