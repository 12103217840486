export const questionTemplate = {
  id: null,
  type: 'questions',
  head: null,
  name: null,
  correct_answer: [],
  answers: [],
  little_help: null,
  big_help: null
}
