import React, { Component } from 'react'
import {
  Heading,
  TextInput,
  TextInputField,
  Text,
  Button,
  Dialog,
  Tablist,
  Tab,
  Pane,
  Label,
} from 'evergreen-ui'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import StageRenderer from './StageRenderer'
import AssetsRenderer from './AssetsRenderer'
import { quillModules } from '../utils'

class QuestDisplayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      removeStageDialog: false,
      removeSubstageDialog: false,
      selectedTab: 1,
    }
  }

  render() {
    const {
      quest,
      updateQuest,
      addStage,
      removeStage,
      // updateStage,
      addSubstage,
      removeSubstage,
      stage,
      selectedStage,
      selectStage,
      stageSubstages,
      setStageCoordines,
      selectSubstage,
      selectedSubstage,
      referencedAssets,
      removeAsset,
      // updatePath,
    } = this.props
    if (quest !== null)
      return (
        <div>
          <Heading size={800} marginBottom={24}>
            Detaily questu
          </Heading>
          <Tablist marginBottom={24}>
            <Tab
              id={0}
              onSelect={() => this.setState({ selectedTab: 0 })}
              isSelected={0 === this.state.selectedTab}
              aria-controls={'panel-basic'}
            >
              Základní informace
            </Tab>
            <Tab
              id={1}
              onSelect={() => this.setState({ selectedTab: 1 })}
              isSelected={1 === this.state.selectedTab}
              aria-controls={'panel-stages'}
            >
              Stanoviště
            </Tab>
            <Tab
              id={2}
              onSelect={() => this.setState({ selectedTab: 2 })}
              isSelected={2 === this.state.selectedTab}
              aria-controls={'panel-assets'}
            >
              Assety
            </Tab>
            {/* <Tab
              id={3}
              onSelect={() => this.setState({ selectedTab: 3 })}
              isSelected={3 === this.state.selectedTab}
              aria-controls={'panel-partners'}
            >
              Audio
            </Tab>
            <Tab
              id={4}
              onSelect={() => this.setState({ selectedTab: 4 })}
              isSelected={4 === this.state.selectedTab}
              aria-controls={'panel-partners'}
              disabled
            >
              Partneři
            </Tab> */}
          </Tablist>
          <Pane
            id={''}
            role="tabpanel"
            // aria-labelledby={true}
            aria-hidden={0 !== this.state.selectedTab}
            display={this.state.selectedTab === 0 ? 'block' : 'none'}
          >
            <TextInputField
              label="název questu"
              width="100%"
              value={quest && quest.name ? quest.name : ''}
              onChange={e => updateQuest({ name: e.target.value })}
              placeholder="Procházka po Praze"
            />
            <TextInputField
              label="ID questu"
              width="100%"
              value={quest && quest.id ? quest.id : ''}
              onChange={e => updateQuest({ id: parseInt(e.target.value) })}
              placeholder="2048"
            />
            <Label
              htmlFor="textarea-description"
              marginBottom={4}
              display="block"
            >
              popis
            </Label>
            <ReactQuill
              modules={quillModules}
              id="textarea-description"
              value={quest && quest.description ? quest.description : ''}
              onChange={value => updateQuest({ description: value })}
            />
            <Label
              htmlFor="textarea-intro"
              marginTop={24}
              marginBottom={4}
              display="block"
            >
              intro
            </Label>
            <ReactQuill
              modules={quillModules}
              id="textarea-intro"
              value={quest && quest.intro ? quest.intro : ''}
              onChange={value => updateQuest({ intro: value })}
            />
          </Pane>
          <Pane
            id={''}
            role="tabpanel"
            // aria-labelledby={true}
            aria-hidden={1 !== this.state.selectedTab}
            display={this.state.selectedTab === 1 ? 'block' : 'none'}
          >
            <Heading size={600} marginBottom={24}>
              stanoviště
            </Heading>
            <StageRenderer
              stages={quest.stages}
              selectStage={selectStage}
              selectedStage={selectedStage}
            />
            <br />
            <br />
            <Button
              appearance="minimal"
              iconBefore="add"
              marginBottom={32}
              onClick={addStage}
            >
              přidat stanoviště
            </Button>
            <Button
              appearance="minimal"
              intent="danger"
              iconBefore="remove"
              marginBottom={32}
              onClick={() => this.setState({ removeStageDialog: true })}
            >
              odebrat stanoviště
            </Button>
            {/* <TextInputField
              label="id stanoviště"
              width="100%"
              value={stage.id ? stage.id : ''}
              onChange={e => updateStage({ id: e.target.value })}
              placeholder="1"
              // disabled
            /> */}
            {/* <Text marginRight={8} marginBottom={8}>
                Název stanoviště:
              </Text>
              <TextInput
                width={300}
                marginBottom={8}
                // onChange={}
                // value={this.stageCoordinates.pos_wgs_lat}
                placeholder="Таборские ворота"
              /> */}
            <br />
            <Text marginRight={8} marginBottom={8}>
              GPS souřadnice:
            </Text>
            <TextInput
              width={100}
              onChange={e =>
                setStageCoordines({
                  pos_wgs_lat: e.target.value,
                })
              }
              value={
                stage.coordinates
                  ? stage.coordinates.pos_wgs_lat
                    ? stage.coordinates.pos_wgs_lat
                    : ''
                  : ''
              }
              placeholder="50.108484"
            />
            <Text marginLeft={8} marginRight={8} marginBottom={8}>
              ,
            </Text>
            <TextInput
              width={100}
              onChange={e =>
                setStageCoordines({
                  pos_wgs_lng: e.target.value,
                })
              }
              value={
                stage.coordinates
                  ? stage.coordinates.pos_wgs_lng
                    ? stage.coordinates.pos_wgs_lng
                    : ''
                  : ''
              }
              placeholder="14.445123"
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Heading size={600} marginBottom={24}>
              substanoviště
            </Heading>
            <StageRenderer
              stages={stageSubstages}
              selectStage={selectSubstage}
              selectedStage={selectedSubstage}
            />
            <br />
            <br />
            <Button
              appearance="minimal"
              iconBefore="add"
              // marginBottom={32}
              onClick={addSubstage}
            >
              přidat substanoviště
            </Button>
            <Button
              appearance="minimal"
              intent="danger"
              iconBefore="remove"
              onClick={() => this.setState({ removeSubstageDialog: true })}
            >
              smazat substanoviště
            </Button>
            <Dialog
              isShown={this.state.removeStageDialog}
              title="Odebrat stanoviště"
              intent="danger"
              onCloseComplete={() =>
                this.setState({ removeStageDialog: false })
              }
              confirmLabel="Odebrat"
              onConfirm={() => {
                this.setState({ removeStageDialog: false })
                removeStage()
              }}
            >
              Opravdu chcete odebrat stanoviště č. {selectedStage + 1}
            </Dialog>
            <Dialog
              isShown={this.state.removeSubstageDialog}
              title="Odebrat substanoviště"
              intent="danger"
              onCloseComplete={() =>
                this.setState({ removeSubstageDialog: false })
              }
              confirmLabel="Odebrat"
              onConfirm={() => {
                this.setState({ removeSubstageDialog: false })
                removeSubstage()
              }}
            >
              Opravdu chcete odebrat substanoviště č. {selectedSubstage + 1}
            </Dialog>
          </Pane>
          <Pane
            id={''}
            role="tabpanel"
            // aria-labelledby={true}
            aria-hidden={2 !== this.state.selectedTab}
            display={this.state.selectedTab === 2 ? 'block' : 'none'}
          >
            <Heading size={600} marginBottom={24}>
              obrázky
            </Heading>
            {/* <TextInputField
              label="cesta k obrázkům"
              width="100%"
              value={pathImages ? pathImages : ''}
              onChange={e => updatePath('image', e.target.value)}
              placeholder="/Grafika_Hradcany"
            /> */}
            <AssetsRenderer
              type="image"
              files={quest.all_images}
              referenced={referencedAssets.images}
              removeAsset={removeAsset}
            />
            <Heading size={600} marginBottom={24}>
              audio
            </Heading>
            {/* <TextInputField
              label="cesta ke zvukům"
              width="100%"
              value={pathSounds ? pathSounds : ''}
              onChange={e => updatePath('sound', e.target.value)}
              placeholder="/Audio_Hradcany"
            /> */}
            <AssetsRenderer
              type="sound"
              files={quest.all_sounds}
              referenced={referencedAssets.sounds}
              removeAsset={removeAsset}
            />
          </Pane>
        </div>
      )
    else
      return (
        <Pane marginTop={100}>
          <Text>
            Začnete tím, že vytvoříte nový quest nebo načtete rozpracovaný quest
            ze souboru.
          </Text>
        </Pane>
      )
  }
}

export default QuestDisplayer
