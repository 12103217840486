import React, { Component } from 'react'
import {
  Heading,
  Label,
  SegmentedControl,
  Button,
  Text,
  TextInputField,
  Pane,
  Card,
  Dialog
} from 'evergreen-ui'

import AnswerRenderer from './AnswerRenderer'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { quillModules } from '../utils'

class QuestEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addAnswerDialog: false,
      removeAnswerDialog: false,
      newAnswerText: '',
      newAnswerImage: ''
    }
  }
  componenDidMount() {
    this.setState({
      answer_type: this.getAnswerType()
    })
  }

  getAnswerType() {
    if (this.props.substage.type === 'questions') {
      if (
        this.props.substage.answers === null &&
        (typeof this.props.substage.correct_answer === 'string' ||
          Array.isArray(this.props.substage.correct_answer))
      ) {
        return 'input'
      }
      if (
        this.props.substage.correct_answer === null &&
        Array.isArray(this.props.substage.answers)
      ) {
        return 'choose'
      }
    }
    return 'no-question'
  }

  render() {
    const {
      selectedStage,
      selectedSubstage,
      substage,
      updateSubstage,
      updateQuestionHelp,
      addAnswer,
      setCorrectAnswer,
      changeAnswerType,
      removeAnswer,
      questionNumber
    } = this.props

    if (substage !== null) {
      return (
        <div>
          <Heading size={800} marginBottom={24}>
            Editor substanoviště
          </Heading>
          <TextInputField
                label="název substanoviště / text otázky"
                width="100%"
                value={
                  substage.name
                    ? substage.name.replace(/<\/?[^>]+(>|$)/g, '')
                    : ''
                }
                onChange={e =>
                  updateSubstage({ name: e.target.value  })
                }
                placeholder="Таборские ворота"
              />
          <Pane display="flex">
            {/* <Pane flex={1} display="flex" marginRight={24}>
              <TextInputField
                label="id"
                width="100%"
                value={substage.id ? substage.id : ''}
                // onChange={e => updateSubstage('id', e.target.value)}
                placeholder="101"
                disabled
              />
            </Pane> */}
            <Pane flex={1}>
              <Label marginBottom={4} display="block">
                typ
              </Label>
              <SegmentedControl
                name="type"
                marginBottom={24}
                options={[
                  {
                    label: 'substanoviště',
                    value: 'substages'
                  },
                  {
                    label: 'otázka',
                    value: 'questions'
                  }
                ]}
                value={substage.type}
                onChange={value => updateSubstage({ type: value })}
              />
            </Pane>
          </Pane>
          {substage.type === 'substages' && (
            <div>
              <TextInputField
                label="obrázek"
                width="100%"
                value={substage.image ? substage.image : ''}
                onChange={e => updateSubstage({ image: e.target.value })}
                placeholder="/stage_1/image.png"
              />
              <TextInputField
                label="zvuk"
                width="100%"
                value={substage.sound ? substage.sound : ''}
                onChange={e => updateSubstage({ sound: e.target.value })}
                placeholder="/stage_1/audio.mp3"
              />
              <Label marginBottom={4} display="block">
                obsah
              </Label>
              <ReactQuill
                modules={quillModules}
                value={substage.body ? substage.body : ''}
                onChange={value => updateSubstage({ body: value })}
              />
            </div>
          )}
          {substage.type === 'questions' && (
            <div>
              <TextInputField
                label="hlavička"
                width="100%"
                value={substage.head ? substage.head : questionNumber(selectedStage, selectedSubstage) + '. Задание'}
                onChange={e => updateSubstage({ head: e.target.value })}
                placeholder="1. Задание"
              />
              <TextInputField
                label="pozadí"
                width="100%"
                value={substage.background ? substage.background : ''}
                onChange={e => updateSubstage({ background: e.target.value })}
                placeholder="/stage_1/background.png"
              />
              <TextInputField
                label="obrázek"
                width="100%"
                value={substage.image ? substage.image : ''}
                onChange={e => updateSubstage({ image: e.target.value })}
                placeholder="/stage_1/image.png"
              />
              <Heading size={600} marginBottom={16}>
                odpověď
              </Heading>
              <Card padding={24} marginBottom={24} border="default">
                <Label marginBottom={4} display="block">
                  typ odpovědi
                </Label>
                <SegmentedControl
                  name="type"
                  marginBottom={24}
                  options={[
                    {
                      label: 'hráč zadá odpověď',
                      value: 'input'
                    },
                    {
                      label: 'hráč vybere z možností',
                      value: 'choose'
                    }
                  ]}
                  value={this.getAnswerType()}
                  onChange={changeAnswerType}
                />
                <Pane>
                  <AnswerRenderer
                    answers={
                      this.getAnswerType() === 'choose'
                        ? substage.answers
                        : this.getAnswerType() === 'input'
                          ? substage.correct_answer
                          : []
                    }
                    setCorrectAnswer={setCorrectAnswer}
                    edit={() => {}}
                    remove={answer => {
                      this.setState({
                        removeAnswerDialog: true,
                        answerToRemove: answer
                      })
                    }}
                  />
                  <Button
                    appearance="minimal"
                    iconBefore="add"
                    onClick={e => this.setState({ addAnswerDialog: true })}
                  >
                    přidat možnost
                  </Button>
                </Pane>
              </Card>
              <Heading size={600} marginBottom={16}>
                malá nápověda
              </Heading>
              <Card padding={24} marginBottom={24} border="default">
                <Label marginBottom={4} display="block">
                  text
                </Label>
                <ReactQuill
                  modules={quillModules}
                  value={
                    substage.little_help
                      ? substage.little_help.body
                        ? substage.little_help.body
                        : ''
                      : ''
                  }
                  onChange={value =>
                    updateQuestionHelp('little_help', 'body', value)
                  }
                />
                <br />
                <TextInputField
                  label="obrázek"
                  width="100%"
                  value={
                    substage.little_help
                      ? substage.little_help.image
                        ? substage.little_help.image
                        : ''
                      : ''
                  }
                  onChange={e =>
                    updateQuestionHelp('little_help', 'image', e.target.value)
                  }
                  placeholder="/stage_1/image.png"
                />
                <TextInputField
                  label="audio"
                  width="100%"
                  marginBottom={0}
                  value={
                    substage.little_help
                      ? substage.little_help.audio
                        ? substage.little_help.audio
                        : ''
                      : ''
                  }
                  onChange={e =>
                    updateQuestionHelp('little_help', 'audio', e.target.value)
                  }
                  placeholder="/stage_1/audio.mp3"
                />
              </Card>
              <Heading size={600} marginBottom={16}>
                velká nápověda
              </Heading>
              <Card padding={24} marginBottom={24} border="default">
                <Label marginBottom={4} display="block">
                  text
                </Label>
                <ReactQuill
                  modules={quillModules}
                  value={
                    substage.big_help
                      ? substage.big_help.body
                        ? substage.big_help.body
                        : ''
                      : ''
                  }
                  onChange={value =>
                    updateQuestionHelp('big_help', 'body', value)
                  }
                />
                <br />
                <TextInputField
                  label="obrázek"
                  width="100%"
                  value={
                    substage.big_help
                      ? substage.big_help.image
                        ? substage.big_help.image
                        : ''
                      : ''
                  }
                  onChange={e =>
                    updateQuestionHelp('big_help', 'image', e.target.value)
                  }
                  placeholder="/stage_1/image.png"
                />
                <TextInputField
                  label="audio"
                  width="100%"
                  marginBottom={0}
                  value={
                    substage.big_help
                      ? substage.big_help.audio
                        ? substage.big_help.audio
                        : ''
                      : ''
                  }
                  onChange={e =>
                    updateQuestionHelp('big_help', 'audio', e.target.value)
                  }
                  placeholder="/stage_1/audio.mp3"
                />
              </Card>
              <Dialog
                isShown={this.state.addAnswerDialog}
                title="Přidat odpověď"
                intent="success"
                onCloseComplete={() =>
                  this.setState({ addAnswerDialog: false })
                }
                confirmLabel="Přidat"
                onConfirm={() => {
                  this.getAnswerType() === 'choose'
                    ? addAnswer({
                        text: this.state.newAnswerText,
                        image: this.state.newAnswerImage
                      })
                    : addAnswer(this.state.newAnswerText)
                  this.setState({
                    addAnswerDialog: false,
                    newAnswerText: '',
                    newAnswerImage: ''
                  })
                }}
                isConfirmDisabled={
                  this.state.newAnswerText.length > 0 || this.state.newAnswerImage.length > 0 ? false : true
                }
              >
                <TextInputField
                  label="text odpovědi"
                  width="100%"
                  value={this.state.newAnswerText}
                  onChange={e =>
                    this.setState({
                      newAnswerText: e.target.value
                    })
                  }
                  placeholder="Он сильно кашлял и чихал, был “чехоточным”."
                />
                {this.getAnswerType() === 'choose' && (
                  <TextInputField
                    label="obrázek"
                    width="100%"
                    value={this.state.newAnswerImage}
                    onChange={e =>
                      this.setState({
                        newAnswerImage: e.target.value
                      })
                    }
                    placeholder="/stage_1/image.png"
                  />
                )}
              </Dialog>
              <Dialog
                isShown={this.state.removeAnswerDialog}
                title="Odebrat odpověď"
                intent="danger"
                onCloseComplete={() =>
                  this.setState({ removeAnswerDialog: false })
                }
                confirmLabel="Odebrat"
                onConfirm={() => {
                  this.setState({ removeAnswerDialog: false })
                  removeAnswer(this.state.answerToRemove, this.getAnswerType())
                }}
              >
                Opravdu chcete odebrat odpověď?
              </Dialog>
            </div>
          )}
        </div>
      )
    } else
      return (
        <Pane marginTop={100}>
          <Text>Poté budete moct upravovat jednotlivé části questu.</Text>
        </Pane>
      )
  }
}

export default QuestEditor
