import React, { Component } from 'react'
import { Table, Icon, IconButton, Dialog } from 'evergreen-ui'

class AssetsRenderer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      removeAssetDialog: false,
      item: 0,
    }
  }

  render() {
    const { type, files, referenced, removeAsset } = this.props
    return (
      <React.Fragment>
        <Table marginBottom={16}>
          <Table.Head>
            <Table.TextHeaderCell
              flexBasis={80}
              flexShrink={0}
              flexGrow={0}
            ></Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={50} flexShrink={0} flexGrow={0}>
              ID
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={500} flexShrink={0} flexGrow={0}>
              cesta k souboru
            </Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={200} flexShrink={0} flexGrow={0}>
              používán
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {files.map((f, i) => {
              return (
                <Table.Row key={i} height={28}>
                  <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0}>
                    <IconButton
                      appearance="minimal"
                      intent="danger"
                      icon="cross"
                      onClick={() =>
                        this.setState({ removeAssetDialog: true, item: i - 1 })
                      }
                    />
                  </Table.TextCell>
                  <Table.TextCell flexBasis={50} flexShrink={0} flexGrow={0}>
                    {++i}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={500} flexShrink={0} flexGrow={0}>
                    {Object.values(f)}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                    {referenced.includes(Object.values(f)[0]) ? (
                      <Icon icon="tick-circle" color="success" />
                    ) : (
                      <React.Fragment>
                        <Icon icon="cross" color="danger" />
                        {type === 'image' ? '(nebo je v textu)' : ''}
                      </React.Fragment>
                    )}
                  </Table.TextCell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <Dialog
          isShown={this.state.removeAssetDialog}
          title="Odebrat soubor"
          intent="danger"
          onCloseComplete={() => this.setState({ removeAssetDialog: false })}
          confirmLabel="Odebrat"
          onConfirm={() => {
            removeAsset(type, this.state.item)
            this.setState({ removeAssetDialog: false })
          }}
        >
          Opravdu chcete odebrat soubor?
        </Dialog>
      </React.Fragment>
    )
  }
}

export default AssetsRenderer
